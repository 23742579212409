* {
  /* So 100% means 100% */
  box-sizing: border-box;
}

html, body {
  background-color: #f7f7f7;
  /* Make the body to be as tall as browser window */
  height: 100%;
}
